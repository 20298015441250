import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  StyleRules,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';

import {
  addCompany,
  addVerification,
  getCompanyGetVerification,
  updateVerification,
} from '../../actions/companyActions';
import ImageCropper from '../../components/ImageCropper';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { createCompanyBasicInfo, createCompanySupplementInfo } from './company.model';
import SuccessPrompt from './SuccessPromptView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    AvatarBorder: {
      marginBottom: '1rem',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    formContainer: {
      margin: '0 auto',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
      width: '800px',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
    stepperContainer: {
      margin: '0 auto',
    },
    stepper: {
      backgroundColor: 'transparent',
    },
    gridItem: {
      paddingTop: theme.spacing(1),
      '& img': {
        maxHeight: 360,
      },
    },
    attachment: {
      minHeight: theme.spacing(10),
    },
    submit: {
      margin: '20px',
    },
    linkSteps: {
      color: 'black',
      textDecoration: 'none',
    },
    inputItem: {
      margin: '20px 0',
    },
    tipFont: {
      color: 'rgba(128, 128, 128, 0.5)',
      textAlign: 'center',
      lineHeight: '40px',
    },
    avatarColor: {
      textAlign: 'center',
      width: '100%',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    contentCenter: {
      margin: '0 auto',
    },
    avatarClass: {
      margin: '1rem',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    avatarText: {
      width: '10rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      overflowWrap: 'break-word',
      fontSize: '0.8rem',
      paddingTop: '1rem',
      color: '#999',
    },
  });

const VerificationView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const company = useSelector((appState: AppState) => appState.company);
  const user = useSelector((appState: AppState) => appState.user);
  const verificationId = company?.verification?.id;
  const [titleInfo, setTitleInfo] = useState<string>('');
  const [descriptionInfo, setDescriptionInfo] = useState<string>('');
  const [editInfo, setEditInfo] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState(0);

  // const [status, setStatus] = useState<JSX.Element>(<></>);
  const [successPromptDisabled, setSuccessPromptDisabled] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const handleChangeSetChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    // setIsDialogUserAgreement(event.target.checked);
    // setIsDialogOpenPrivacyAgreement(event.target.checked);
  };

  const handleVerificationAdd = async (): Promise<void> => {
    if (!isBasicInfoValid) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '基本信息验证错误',
        })
      );
      return;
    }
    triggerSupplementInfo('CompanySupplementInfo');
    if (!isSupplementInfoValid) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '其他信息验证错误',
        })
      );
      return;
    }
    if (!checked) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '请确认阅读完成注册协议',
        })
      );
      return;
    }
    const result = await dispatch(addCompany());
    if (result) {
      try {
        const data = {
          id: verificationId,
          basicInfo: getBasicInfoValues('CompanyBasicInfo'),
          supplementInfo: getSupplementInfoValues('CompanySupplementInfo'),
          status: 'SUBMITTED',
        };
        await dispatch(addVerification(data));
        setActiveStep(1);
        setTitleInfo('你已注册企业用户!');
        setDescriptionInfo('提交信息成功, 您可以尝试发布项目。');
        setSuccessPromptDisabled(true);
        setEditInfo(true);
      } catch (error) {
        console.log('提交错误', error);
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: '注册失败',
          })
        );
      }
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '注册失败',
        })
      );
    }
  };

  const handleEditVerificationUpdate = async () => {
    // triggerSupplementInfo('CompanySupplementInfo');
    // if (!isSupplementInfoValid) {
    //   console.log('其他信息验证错误', errorsSupplementInfo);

    //   dispatch(
    //     showNotificationAction({
    //       severity: 'error',
    //       message: '其他信息验证错误',
    //     })
    //   );
    //   return;
    // }

    try {
      const data = {
        id: verificationId,
        basicInfo: getBasicInfoValues('CompanyBasicInfo'),
        supplementInfo: getSupplementInfoValues('CompanySupplementInfo'),
        status: 'SUBMITTED',
      };

      await dispatch(updateVerification(data));
      setActiveStep(1);
      setTitleInfo('你已修改企业信息!');
      setDescriptionInfo('修改信息成功, 您可以尝试发布项目。');
      setSuccessPromptDisabled(true);
      setEditInfo(true);
    } catch (error) {
      console.log('提交错误');
    }
  };

  const {
    setValue: setSupplementInfoValue,
    control: controlSupplementInfo,
    getValues: getSupplementInfoValues,
    trigger: triggerSupplementInfo,

    // reset: restSupplementInfo,
  } = useForm({
    defaultValues: {
      CompanySupplementInfo: createCompanySupplementInfo(),
    },
    mode: 'onChange',
  });

  const {
    // isDirty: isSupplementInfoDirty,
    errors: errorsSupplementInfo,
    isValid: isSupplementInfoValid,
  } = useFormState({
    control: controlSupplementInfo,
  });

  const {
    setValue: setBasicInfoValue,
    control: controlBasicInfo,
    getValues: getBasicInfoValues,
    // reset: restBasicInfo,
  } = useForm({
    defaultValues: {
      CompanyBasicInfo: createCompanyBasicInfo(),
    },
    mode: 'onChange',
  });

  const {
    // isDirty: isBasicInfoDirty,
    errors: errorsBasicInfo,
    isValid: isBasicInfoValid,
  } = useFormState({
    control: controlBasicInfo,
  });

  const handleCrop = (blob: string) => {
    if (!user.company?.id) {
      setBasicInfoValue('CompanyBasicInfo.avatar', blob);
      dispatch(showNotificationAction({ severity: 'success', message: '已保存' }));
    } else {
      setBasicInfoValue('CompanyBasicInfo.avatar', blob);
      triggerSupplementInfo('CompanySupplementInfo');
      handleEditVerificationUpdate();
    }
  };

  const verificationCompanyOtherInfoView = (
    <Grid container>
      <Grid item container xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              multiline
              minRows={4}
              label="企业业务介绍"
              error={!!errorsSupplementInfo.CompanySupplementInfo?.businessDescription}
              helperText={
                !!errorsSupplementInfo.CompanySupplementInfo?.businessDescription &&
                '请输入企业业务介绍'
              }
            />
          )}
          control={controlSupplementInfo}
          name="CompanySupplementInfo.businessDescription"
          rules={{ required: true }}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              fullWidth
              label="公司网站"
              size="small"
            />
          )}
          control={controlSupplementInfo}
          name="CompanySupplementInfo.enterpriseWebsite"
        ></Controller>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        item
        alignItems="center"
        xs={12}
      >
        {company.verification?.status == 'NONE' || !company.verification?.status ? (
          <Typography variant="subtitle2" align="center">
            <Checkbox color="primary" checked={checked} onChange={handleChangeSetChecked} />
            请确认阅读完成 &nbsp;
            <span>
              <a
                href="https://www.fintegrity.cn/user"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                注册协议
              </a>
            </span>
          </Typography>
        ) : (
          ''
        )}
      </Grid>
      <Grid
        xs={12}
        container
        direction="row"
        justifyContent="space-around"
        item
        alignItems="center"
      >
        {(company?.verification?.status == 'SUBMITTED' ||
          company?.verification?.status == 'VERIFIED') && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              setEditInfo(!editInfo);
            }}
            size="small"
          >
            {editInfo ? '取消' : '编辑'}
          </Button>
        )}
        {company.verification?.status == 'NONE' || !company.verification?.status ? (
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            className={classes.submit}
            // disabled={
            //   buttonDisabled
            // }
            onClick={(e): void => {
              e.preventDefault();
              handleVerificationAdd();
            }}
          >
            同意并注册
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!editInfo}
            onClick={(e): void => {
              e.preventDefault();
              handleEditVerificationUpdate();
            }}
            size="small"
          >
            修改信息
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const verificationCompanyBasicInfoView = (
    <Grid container>
      <div className={classes.avatarClass}>
        <ImageCropper
          onCrop={handleCrop}
          avatarImage={getBasicInfoValues('CompanyBasicInfo.avatar')}
        />
        <div className={classes.avatarText}>
          上传的头像会自动生成头像缩略图，您也可 以拖动大图的裁减区域，调整缩图内容。
          支持JPG、GIF、PNG等图片格式 推荐尺寸：180*180像素
        </div>
      </div>

      <Grid item container xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              label="企业/组织全称"
              error={!!errorsBasicInfo.CompanyBasicInfo?.registrationName}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.registrationName && '请输入企业名称'}
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.registrationName"
          rules={{ required: true }}
        ></Controller>

        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              {...field}
              label="企业简称"
              placeholder="公司简称会作为公司名称在傅通自由职业平台显示"
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.alias"
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              required
              size="small"
              variant="outlined"
              margin="normal"
              fullWidth
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              label="企业/组织注册编号"
              error={!!errorsBasicInfo.CompanyBasicInfo?.registrationId}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.registrationId && '请输入企业注册编号'
              }
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.registrationId"
          rules={{ required: true }}
        ></Controller>

        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.companyIndustry"
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              error={!!errorsBasicInfo.CompanyBasicInfo?.companyIndustry}
            >
              <InputLabel htmlFor="label-gender">企业行业</InputLabel>
              <Select
                labelId="label-EnterpriseSize"
                id="EnterpriseSize"
                label="企业行业"
                {...field}
                // readOnly={summaryMode || verifyDisabled}
                // disabled={summaryMode || verifyDisabled}
              >
                <MenuItem value={''} disabled>
                  -请选择-
                </MenuItem>
                <MenuItem value={'service'}>服务业</MenuItem>
                <MenuItem value={'manufacture'}>制造业</MenuItem>
                <MenuItem value={'construction'}>建筑业</MenuItem>
                <MenuItem value={'farming'}>农业</MenuItem>
                <MenuItem value={'IT'}>信息技术业</MenuItem>
                <MenuItem value={'finance'}>金融业</MenuItem>
                <MenuItem value={'traffic'}>交通运输业</MenuItem>
              </Select>
              {!!errorsBasicInfo.CompanyBasicInfo?.companyIndustry && (
                <FormHelperText>请选择公司行业</FormHelperText>
              )}
            </FormControl>
          )}
        ></Controller>

        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.enterpriseSize"
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              required
              error={!!errorsBasicInfo.CompanyBasicInfo?.enterpriseSize}
            >
              <InputLabel htmlFor="label-gender">企业规模</InputLabel>
              <Select
                labelId="label-EnterpriseSize"
                id="EnterpriseSize"
                label="企业规模"
                {...field}
                // readOnly={summaryMode || verifyDisabled}
                // disabled={summaryMode || verifyDisabled}
              >
                <MenuItem value={''} disabled>
                  -请选择-
                </MenuItem>
                <MenuItem value={'mini'}>小微企业 20人以下</MenuItem>
                <MenuItem value={'small'}>中小型企业 20-300人</MenuItem>
                <MenuItem value={'medium'}>中大型企业 300-1000人</MenuItem>
                <MenuItem value={'big'}>大型企业 1000+</MenuItem>
              </Select>
              {!!errorsBasicInfo.CompanyBasicInfo?.enterpriseSize && (
                <FormHelperText>请选择公司规模</FormHelperText>
              )}
            </FormControl>
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.enterpriseLegalPerson"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              {...field}
              label="联系人(或运营者)姓名"
              error={!!errorsBasicInfo.CompanyBasicInfo?.enterpriseLegalPerson}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.enterpriseLegalPerson &&
                '请输入联系人(或运营者)姓名'
              }
            />
          )}
        ></Controller>

        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactAddress"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              required
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              {...field}
              label="联系地址"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactAddress}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.contactAddress && '请输入联系地址'}
            />
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactPhone"
          rules={{
            required: true,
            pattern: {
              value: /^1[3-9]\d{9}$/,
              message: '请输入正确的手机号',
            },
          }}
          render={({ field }) => (
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              {...field}
              label="联系电话"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactPhone}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.contactPhone &&
                `${errorsBasicInfo.CompanyBasicInfo?.contactPhone.message}`
              }
            />
          )}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={
                (company?.verification?.status == 'SUBMITTED' ||
                  company?.verification?.status == 'VERIFIED') &&
                !editInfo
                  ? true
                  : false
              }
              label="联系邮箱"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactEmail}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.contactEmail && '请输入联系邮箱'}
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactEmail"
          rules={{ required: true }}
        ></Controller>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      ></Grid>
    </Grid>
  );

  useEffect(() => {
    if (user.id && user.company?.id) {
      dispatch(getCompanyGetVerification(company.id));
    }
  }, []);

  useEffect(() => {
    if (user.id && user.company?.id) {
      dispatch(getCompanyGetVerification(company.id));
    }
  }, [user.company?.id]);

  useEffect(() => {
    if (company.verification?.basicInfo && user.company?.id) {
      setBasicInfoValue('CompanyBasicInfo', company.verification?.basicInfo);
    }
    if (company.verification?.supplementInfo && user.company?.id) {
      setSupplementInfoValue('CompanySupplementInfo', company.verification.supplementInfo);
    }
  }, [company.verification?.basicInfo, company.verification?.supplementInfo, user.company?.id]);

  return (
    <Container className={classes.root} component="main" maxWidth="lg">
      <Helmet title="企业注册信息"></Helmet>
      {!successPromptDisabled && (
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.formContainer}>
              {activeStep == 0 && verificationCompanyBasicInfoView}
              {activeStep == 0 && verificationCompanyOtherInfoView}
            </Paper>
            <p className={classes.tipFont}>*为必填，基本信息必填、基本信息提交后不能更改</p>
          </Grid>
        </Grid>
      )}
      {successPromptDisabled && (
        <SuccessPrompt
          title={titleInfo}
          description={descriptionInfo}
          transform={() => {
            navigate('/company');
          }}
        />
      )}
    </Container>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  inputEmail?: string | undefined;
  inputSecret?: string | undefined;
  inputAuth?: string | undefined;
}

export default withStyles(styles)(VerificationView);
