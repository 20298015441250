import React from 'react';

import Layout from '../../layout/CompanyLayout';
import VerificationView from '../../views/company/VerificationView';

const RegisterPage = (): React.ReactElement => {
  return (
    <Layout>
      <VerificationView />
    </Layout>
  );
};

export default RegisterPage;
